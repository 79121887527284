.container {
  width: 100%;
  max-width: 100%; /* 최대 너비 설정 */
  min-height: calc(6vh); /* 최소 높이를 브라우저 창 높이의 6% */
  word-break: keep-all;
  margin: 0 auto; /* 가운데 정렬 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
}
  
.button {
  width: 110px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.button:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
  .button {
    width: 15vw;
    font-size: small;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 10px;
    word-break: keep-all;
    margin: 0 auto;
    padding-left: 5%;
  }
}

@media (max-width: 430px) {
  .button {
    width: 19vw;
    font-size: small;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 10px;
    word-break: keep-all;
    margin: 0 auto;
    padding-left: 5%;
  }
}