
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    padding-top: 80px;
    padding-bottom: 100px;
}

.formMyPage {
    background: #fff;
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-color);
    padding: 70px;
    border-radius: 11px;
    min-width: 50svw;
    max-width: 50vw;
    text-align: center;
}

.formMyPage > h1 {
    margin-top: 10px;
    margin-bottom: 28px;
    color: var(--main-color);
    font-size: 2em;
    font-family: "Manrope", Helvetica;
    padding-left: 10px;
    padding-bottom: 10px;
}

.formMyPage > div {
    margin-bottom: 15px;
}

.formMyPage > div > div {
    position: relative;
}

.formMyPage input[type="text"],
.formMyPage input[type="name"],
.formMyPage input[type="password"],
.formMyPage input[type="password"],
.formMyPage input[type="phone"] {
    background: #fff;
    border-radius: 4px;
    outline: 0;
    border: 1px solid var(--secondary-color);
    box-shadow: var(--box-shadow);
    padding: 15px 32px;
    width: 70%;
    font-family: Arial, Helvetica, sans-serif;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.formMyPage input[type="text"]:focus,
.formMyPage input[type="name"]:focus,
.formMyPage input[type="password"]:focus,
.formMyPage input[type="password"]:focus,
.formMyPage input[type="phone"]:focus {
    border: 1px solid var(--main-color);

}

.formMyPage button[type="submit"] {
    width: 30%;
    border-radius: 8px;
    outline: 0;
    border: none;
    color: #ffffff;
    padding: 10px;
    margin: 15px 0;
    background: var(--main-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: "Manrope", Helvetica;
    font-weight: 500;
    font-size: 1em;
  }
  
  .formMyPage button[type="submit"]:hover {
    background: var(--main-color-hover);
    cursor: pointer;
  }

  .formMyPage input::placeholder {
    color: #bfbbbb;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .formMyPage input[name="phone"]::placeholder,
  .formMyPage input[name="name"]::placeholder {
    color: #000;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .formMyPage > div span.error {
    height: 10px;
    text-align: left;
    margin-top: 5px;
    width: fit-content;
    font-size: 0.7rem;
    color: red;
    line-height: 1px;
    padding: 9px 3px;
    border-radius: 3px;
    background-color: rgba(255, 0, 0, 0.175);
    display: inline-block;
  }