.container {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    min-height: calc(65vh);
    word-break: keep-all;
    margin: 0 auto;
  }

  .bannerContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 0.2vw;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .bannerImage {
    width: 100%;
    aspect-ratio: 3 / 1;
    object-fit: cover;
    border-radius: 10px;
  }

  .writeContainer {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    min-height: calc(65vh);
    word-break: keep-all;
    margin: 0 auto;
    display: flex;          /* 플렉스 박스 사용 */
    flex-direction: column;    /* 가로로 나열 */
    gap: 20px; 
  }

  .writeContainer h1 {
    margin-top: 50px;
    margin-bottom: 28px;
    color: var(--main-color);
    text-align: center;
    font-size: 200%;
    font-weight: bold;
    font-family: "Manrope", Helvetica;
  }

 .container h1 {
    margin-top: 50px;
    margin-bottom: 28px;
    color: var(--main-color);
    text-align: center;
    font-size: 200%;
    font-weight: bold;
    font-family: "Manrope", Helvetica;
  }
  
.buttonContainer{
    width: 100%;
    max-width: 100%;
    padding-bottom: 2%;
    min-height: calc(6vh);
    word-break: keep-all;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

  .ls {
    float: left;
  }
  
  .rs {
    min-height: 100vh;
    padding-left: 180px;
  }
  
  .datagrid {
    width: 100%;
  }
  
  .datagrid_item {
    cursor: pointer;
  }
  
  .paper {
    padding: 20px;
    flex: 1;
  }
  
  .title_row {
    height: 30px;
  }
  
  .tableRow {
    cursor: pointer;
  }

  .table_ {
    width: 100%;
    margin: 10px;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table_th {
    font-size: 1.7em;
    justify-content: center; /* 가로 가운데 정렬 (필요한 경우) */
    text-align: left;
    height: 100%;           /* 부모 요소의 높이를 기준으로 가운데 정렬 */
    padding-right: 10px;
  }

  .formPadding {
    padding-left: 2%;
    padding-right: 2%;
    word-break: break-word; /* 단어 잘림 방지 */
    overflow-wrap: break-word; /* 줄바꿈 보장 */
    width: 65vw;
  }

  .inputSmall {
    width: 150px;
    text-align: left;
  }
  
  .table_th_2 {
    font-size: 1.7em;
    text-align: left; 
    padding-left: 0;
  }
  
  .table_td_1 {
    text-align: right;
    font-size: 0.9em;
    padding: 10px 15px 40px 0px;
  }
  
  .table_td_2 {
    border-bottom: 1px solid #444444;
    padding-bottom: 30px;
    width: 100%;
    word-break: break-all;
    padding-right: 10px;
  }
  
  .list {
    margin: 70px 10px 0px 10px;
  }
  
  .prev_next_btn {
    color: #2f5597;
    padding-right: 20px;
  }
  
  .list_item:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  
  .listIcon {
    float: right;
    margin: 5px 10px 30px 0px;
    cursor: pointer;
    color: white;
    background-color: #808080;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 7px;
  }
  
  .radioButton {
    background-color: transparent;
  }

  .radioButton:hover {
    color: green;
  }
  
  .radioButton.selected {
    background-color: green;
    color: white;
    border-color: green;
  }
  
  .radioButton.selected:hover {
    color: white;
  }

  .radioButton:focus {
    outline: none; /* 클릭 시 파란색 outline 제거 */
  }
  
  .pinnedRow {
    background-color: #e4f4e4; /* 회색 배경 */
    cursor: pointer;
  }

  .formPaddingRight {
    padding-right: 40px;
  }

  .customCard {
    width: 13rem;
    margin: 0.4rem;
    text-align: left;
  }

  .cardCover {
    width: 11rem  !important;
    height: 11rem;
    margin: 1rem auto;
    object-fit: cover;
  }

  .cardTitle {
    font-size: 1rem;
    font-weight: bold;
    margin: 0rem 0;
  }

  .customCard .ant-card-body {
    padding: 10px !important; /* 기본 패딩을 10px로 변경 */
  }


  @media (max-width: 1280px) {
    .container {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 2%;
      min-height: calc(65vh);
      word-break: keep-all;
      margin: 0 auto;
    }
    .buttonContainer{
      grid-template-columns: 2fr;
      flex-direction: column;
      gap: 10px;
    }
    .writeContainer {
      padding-left: 1%;
      padding-right: 1%;
      padding-bottom: 2%;
      gap: 10px; 
    }
    .formPaddingRight {
      padding-right: 0px;
    }
    .formPadding {
      padding-left: 2%;
      padding-right: 2%;
      word-break: break-word; /* 단어 잘림 방지 */
      overflow-wrap: break-word; /* 줄바꿈 보장 */
      width: 80vw;
    }

    @media (max-width: 430px) {
      .container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
      .customCard {
        width: 6.5rem;
        margin: 0.1rem;
      }
      .cardCover {
        height: 6.5rem;
        width: 5.3rem !important;
        margin: 0.5rem auto;
      }
      .cardTitle {
        font-size: 0.7rem;
      }
      .cardDescription {
        font-size: 0.6em;
      }
    }

    @media (max-width: 420px) {
      .container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
      .customCard {
        width: 6rem;
        margin: 0.1rem;
        padding: 0px !important;
      }
      
      .cardCover {
        height: 6.5rem;
        width: 5.3rem !important;
        margin: 0.5rem auto;
      }
      .cardTitle {
        font-size: 0.7rem;
      }
      .cardDescription {
        font-size: 0.6em;
      }
    }
  }