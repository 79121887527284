.container {
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
}


.edit_button {
  display: inline-block;
  margin-right: 10px;
}

.delete_button {
  display: inline-block;
}