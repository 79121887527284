.container {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.addbutton {
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.addbutton:hover {
    transform: scale(1.05);
}

@media(max-width: 460px){
  .addbutton {
    width: 18vw;
    padding-left: 5px;
    padding-right: 5px;
  }
}