.container {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    min-height: calc(65vh);
    word-break: keep-all;
    margin: 0 auto;
    overflow: hidden;
}

.likeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;
    border-bottom: 2px solid rgba(0, 115, 47, 1);
}

.category {
    margin-top: 50px;
    margin-bottom: 28px;
    color: var(--main-color);
    text-align: center;
    font-size: 200%;
    font-weight: bold;
    font-family: "Manrope", Helvetica;
}

.ls {
float: left;
}

.rs {
    min-height: 100vh;
    padding-left: 180px;
}

.datagrid {
width: 100%;
}

.datagrid_item {
cursor: pointer;
}

.paper {
padding: 20px;
}

.title_row {
height: 30px;
}

.table_row {
cursor: pointer;
}

.table_ {
    width: 100%;
    border-collapse: collapse;
}

.table_title {
    font-size: 2em;
    padding: 10px 15px 30px 10px;
    text-align: left;
}

.table_th_2 {
    font-size: 1.7em;
}

.table_info {
    text-align: left;
    font-size: 0.9em;
    padding: 10px 15px 40px 10px;
    width: 100%;
    border-bottom: 1px solid rgb(161, 161, 161);
    max-width: 76vw;
    display: block;
}

.table_td_2 {
    padding: 80px 10px 80px 10px;
    line-height:2;
    width: 100%;
    word-break: break-all;
    overflow-x: auto;
    position: relative;
    box-sizing: border-box;
    display: block; /* 기본적으로 블록으로 설정 */
}

.list {
    margin: 70px 10px 0px 10px;
    padding-bottom: 100px;
}

.prev_btn {
    padding-left: 10px;
    padding-right: 20px;
}

.icon_container{
    padding-right: 20px;
    color: #00732f;
}

.list_item:hover {
cursor: pointer;
background-color: #f6f6f6;
}

.author_container {
    padding-bottom: 20px;
}

.infoSpan {
    padding-right: 50px;
}

.iconWithText {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.subCategory_box{
    border-top: 2px solid rgba(0, 115, 47, 1);
    padding: 40px 15px 20px 10px;
    text-align: left;
    max-width: 76vw;
    display: block;
}

.likeBtn {
    transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.likeBtn:hover {
    transform: scale(1.05);
}

/* real estate post's properties */
.detailTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9em;
    color: #333;
    margin-bottom: 20px;
}

.detailTable td {
    padding: 10px;
    border: 1px solid #ddd;
    vertical-align: top;
}

.detailTable td:first-child {
    font-weight: bold;
    background-color: #f9f9f9;
    width: 30%;
}

.detailTable td:last-child {
    width: 70%;
    text-align: left;
}



@media (max-width: 480px) {

    .container {
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 2%;
    }
    .likeBtn {
        width: 5vw;
        font-size: 3vw;
    }
    .icon_container{
        padding-right: 6px;
        color: #00732f;
    }
    .subCategory_box{
        padding: 40px 0px 20px 6px;
        text-align: left;
        max-width: 92vw;
        display: block;
        width: 100%;
        font-size: small;
    }
    .table_info {
        text-align: left;
        font-size: 0.9em;
        padding: 10px 0px 40px 10px;
        width: 100%;
        border-bottom: 1px solid rgb(161, 161, 161);
        max-width: 87vw;
        display: block;
    }
    .table_title {
        font-size: 1.6em;
        padding: 10px 15px 20px 10px;
        text-align: left;
    }
    .table_td_2 {
        padding: 80px 10px 80px 10px;
        line-height:2;
        width: 87vw;
        word-break: break-all;
        overflow-x: auto;
        position: relative;
        box-sizing: border-box;
        display: block; /* 기본적으로 블록으로 설정 */
    }
    .infoSpan {
        padding-right: 15px; /* 패딩 축소 */
    }
    .iconWithText {
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        gap: 5px;
    }
}