* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: rgba(0, 115, 47, 1);
  --secondary-color: #d7e3ef;
  --box-shadow: 0 0 7px 0 var(--secondary-color);
  --main-color-hover: #183524;
}

body {
  font-size: 14px;
  color: #303030;
  overflow-x: hidden;
  background: #f8f9fa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: var(--main-color);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

a:hover {
  color: var(--main-color-hover);
}

.formLogin {
  background: #fff;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--secondary-color);
  padding: 25px;
  border-radius: 11px;
  min-width: 500px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding-top: 80px;
  padding-bottom: 100px;
}

.formLogin > h1 {
  margin-top: 10px;
  margin-bottom: 28px;
  color: var(--main-color);
  text-align: center;
  font-size: 200%;
  font-family: "Manrope", Helvetica;
}

.formLogin > div {
  margin-bottom: 15px;
}

.formLogin > div > div {
  position: relative;
}

.formLogin input[type="text"],
.formLogin input[type="email"],
.formLogin input[type="password"] {
  background: #fff;
  border-radius: 4px;
  outline: 0;
  border: 1px solid var(--secondary-color);
  box-shadow: var(--box-shadow);
  padding: 15px 32px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.formLogin input[type="text"]:focus,
.formLogin input[type="email"]:focus,
.formLogin input[type="password"]:focus {
  border: 1px solid var(--main-color);

}

.unCompleted > input {
  border: 1px solid #ff0000 !important;
}

.completed > input {
  border: 1px solid #88ffc3 !important;
  position: relative;
}

.unCompleted::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.completed::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.formLogin input[type="checkbox"] {
  position: relative;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--main-color);
  appearance: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.formLogin input[type="checkbox"]:checked {
  background-color: var(--main-color-hover);
  
}

.formLogin input[type="checkbox"]:focus {
  outline: none;
  border: 2px solid var(--main-color-hover);
}

.formLogin input[type="date"] {
  background: #fff;
  border-radius: 4px;
  outline: 0;
  border: 1px solid var(--secondary-color);
  box-shadow: var(--box-shadow);
  padding: 15px 32px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.3s ease-in-out;
}

.formLogin input[type="date"]:focus {
  border: 1px solid var(--main-color);
}

.formLogin > div > div img {
  position: absolute;
  max-width: 20px;
  max-height: 20px;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.formLogin ::placeholder {
  color: #bfbbbb;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.formLogin > div span.error {
  height: 10px;
  text-align: left;
  margin-top: 5px;
  width: fit-content;
  font-size: 0.7rem;
  color: red;
  line-height: 1px;
  padding: 9px 3px;
  border-radius: 3px;
  background-color: rgba(255, 0, 0, 0.175);
  display: inline-block;
}

.formLogin button[type="submit"] {
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  outline: 0;
  border: none;
  color: #ffffff;
  padding: 10px;
  margin: 15px 0;
  background: var(--main-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: "Manrope", Helvetica;
}

.formLogin button[type="submit"]:hover {
  background: var(--main-color-hover);
  cursor: pointer;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 19px 0;
  font-family: "Manrope", Helvetica;
}

.formLogin .logo {
  width: 60px !important;
  max-width: 100% !important; /* 이미지가 부모 요소를 넘지 않도록 */
  height: auto !important; /* 높이 비율을 자동 조절 */
  display: block;
  margin: 0 auto 10px;
}

.logoHandubi {
  color: var(--accent);
  font-family: "Manrope", Helvetica;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -1.8px;
  white-space: nowrap;
  margin-top: 10px;
}

.logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 100px;
}

.policyPopup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%; /* 화면 크기에 따라 자동 조정 */
  max-width: 300px; /* 최대 너비 제한 */
  top: -10px;
  left: 50%; /* 수평 가운데 정렬 */
  transform: translateX(-30%); /* 가운데로 이동 */
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border-radius: 4px;
  word-wrap: break-word; /* 긴 단어도 줄바꿈 */
  overflow-wrap: break-word; /* 내용 줄바꿈 */
  text-align: left;
  z-index: 9999;
}

.policyPopup h3 {
  margin: 0 0 10px;
  font-size: 16px;
}

.policyPopup p {
  margin: 0;
  line-height: 1.5;
}

.policyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.policyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 1vw 100px 1vw;
  line-height: 30px;
}

.policyContainer > h1 {
  padding-top: 5vw;
  color: var(--main-color);
  font-size: 200%;
  align-items: center;
  font-family: "Manrope", Helvetica;
}

.policyContainer ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}
.policyContainer a {
  color: rgba(0, 115, 47, 1);
  text-decoration: none;
}
.policyContainer a:hover {
  text-decoration: underline;
}

/* ===== 반응형 스타일 추가 ===== */

/* 태블릿 화면 (너비 768px 이하) */
@media (max-width: 768px) {
  .container {
    max-width: 80vw;
    padding-top: 0px;
    padding-bottom: 100px;
    margin: 10vw;
  }
  
  .policyContent {
    max-width: 80vw;
    padding: 0px 10vw 100px 10vw;
    line-height: 30px;
    margin: 10vw;
  }
  
  .formLogin {
    background: none;
    box-shadow: none;
    border: none;
    padding: 10vw;
    border-radius: 11px;
    max-width: 50vw;
  }

  .formLogin input {
    background: #fff;
    padding: 15px 32px;
    width: 50vw;
  }

  .formLogin input[type="checkbox"] {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    padding: 0px
  }
}

/* 모바일 화면 (너비 480px 이하) */
@media (max-width: 480px) {
  .container {
    max-width: 80vw;
    padding-top: 0px;
    padding-bottom: 100px;
    margin: 10vw;
    overflow: hidden;
  }

  .policyContent {
    max-width: 80vw;
    padding: 0px 10vw 100px 10vw;
    line-height: 30px;
    margin: 10vw;
  }

  .formLogin {
    background: none;
    box-shadow: none;
    border: none;
    padding: 25vw;
    border-radius: 11px;
    max-width: 80vw; /* 화면 너비의 90%로 제한 */
    overflow: hidden; /* 내부 내용이 초과될 경우 숨기기 */
  }

  .formLogin input {
    background: #fff;
    padding: 15px 32px;
    width: 50vw;
  }

  .formLogin input[type="checkbox"] {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    padding: 0px
  }
  .policyPopup {
    width: 90%; /* 작은 화면에서는 폭을 더 좁게 */
    top: 10px; /* 화면에 맞게 위치 조정 */
    transform: translateX(-50%) translateY(-30%);/* 가운데로 이동 */
  }
}
