.searchWrapper {
    gap: 20px;
    padding: 0px 0px 0px 0px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: clamp(30rem, 5vw, 50rem);
    color: #ffffff;
  }
  
  .inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 요소들이 양 끝에 위치하도록 설정 */
    padding-left: 15px;
    padding-right: 0; /* 필요 시 padding 조정 */
    background-color: #f3f8f5;
    border-radius: 5px;
    height: 55px;
    width: 100%;
    transition: transform 0.3s ease;
  }

  .inputWrapper:hover {
    transform: scale(1.05);
  }

  .searchInput {
    background-color: #f3f8f5;
    pointer-events: auto;
    font-family: "Manrope", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    text-align: left;
    line-height: normal;
    border: none;
    outline: none;
    flex: 1;
    padding-right: 10px;
    margin-right: 10px;
  }
  
  .searchBtnContainer {
    background-color: #2d2d2d;
    border-radius: 0px 5px 5px 0px;
    height: 55px;
    justify-content: center;
    align-items: center;
    width: 133px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .btnTextContainer {
    display: flex;
    align-items: center;
    height: 14px;
    left: 26px;
    position: relative;
    top: 20px;
    width: 84px;
  }

  .searchBtnContainer:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transform: scale(1.05);
}
  
  .btnText {
    color: #fffefc;
    font-family: "Manrope", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    left: 24px; 
    text-align: left;
    line-height: normal;
    top: 1px;
    margin-left: 25px;
  }
  
  .search {
    height: 14px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
  }
  
  @media (max-width: 768px) {
    .searchWrapper {
      width: 70vw; /* 화면 너비에 맞게 조정 */
      padding: 0px;
      margin: 0px;
      height: 10vh;
      margin-top: 5vh;
    }
  
    .inputWrapper {
      width: 80vw; /* 버튼 너비 축소 */
      padding-left: 20px;
      margin: 0px;
    }
  
    .searchBtnContainer {
      width: 18vw; /* 버튼 너비 축소 */
      align-items: center;
      justify-items: center;
      display: flex;
      top: 0px;
      left: 0px;
    }

    .btnTextContainer {
      align-items: center;
      justify-items: center;
      height: 14px;
      width: 84px;
      top: 0px;
      left: 1vw;
    }
  
    .btnText {
      font-size: 14px; /* 버튼 텍스트 크기 축소 */
    }
  }
  
  /* 모바일 화면 (너비 480px 이하) */
  @media (max-width: 480px) {
    .searchWrapper {
      width: 100%; /* 화면의 100% 너비로 조정 */
      padding-bottom: 1vw;
    }
  
    .inputWrapper {
      height: 45px; /* 높이 축소 */
      padding-left: 15px;
    }
  
    .searchInput {
      font-size: 14px; /* 텍스트 크기 축소 */
    }
  
    .searchBtnContainer {
      width: 90px; /* 버튼 너비 축소 */
      height: 45px; /* 높이 축소 */
      align-items: center;
      justify-items: center;
    }
  
    .btnTextContainer {
      align-items: center;
      justify-items: center;
      height: 14px;
      width: 84px;
      top: 0px;
      left: 2vw;
    }

    .btnText {
      font-size: 12px; /* 버튼 텍스트 크기 축소 */
    }
  }