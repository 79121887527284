.bannerContainer {
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 100%; /* 각 슬라이드가 컨테이너 너비의 100%를 차지하도록 설정 */
}

.slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.arrow {
  position: absolute;
  font-size: 5vw;
  color: white;
  cursor: pointer;
  z-index: 10;
  padding: 3vw;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.arrow:hover {
  position: absolute;
  transform: translateY(-50%);
  font-size: 5vw;
  color: white;
  cursor: pointer;
  z-index: 10;
  transform: scale(1.1);
}

.arrow.left {
  left: 2vw;
}

.arrow.right {
  right: 2vw;
}

.box {
  display: grid;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto); */
  gap: 3px;
  max-width: 80%;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
}

.box1 {
  /* grid-column: 1 / span 1; */
  /* grid-row: 1 / span 3; */
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 3;
  grid-row: 1;
  width: 100%;
}

.box2,
.box3,
.box4 {
  /* grid-column: 2 / span 1; */
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: span 1;
}

.box2 {
  grid-row: 2;
}

.box3 {
  grid-row: 2;
}

.box4 {
  grid-row: 2;
}

.bigImage{
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.smallImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .box {
    max-width: 100%;
  }
  .smallImage{
    aspect-ratio: 3 / 1;
  }
  .slide img {
    width: 100%;
  }
}

/* 모바일 화면 (너비 768px 이하) */
@media (max-width: 820px) {
  .bannerContainer {
    padding: 0px;
    height: 100%;
    border-radius: 0px;
  }

  .slide img {
    width: 100%;
  }

  .box {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 3px;
    max-width: 95%;
  }

  .box1 {
      grid-column: 1 / span 3;
      grid-row: 1;
      width: 100%;
  }

  .box2,
  .box3,
  .box4 {
      grid-column: span 1;
      grid-row: 2;
  }
}