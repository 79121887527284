.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 3vw 2vw 3vw 2vw;
    background-color: white;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 17%;
    padding-right: 2%;
    padding-left: 1%;
    left: 0;
}

.logo {
    height: 4vw; /* 화면 너비에 비례하여 크기 조정 */
    width: 4vw;
}

.logoHandubi {
    color: #00732f;
    font-family: "Manrope", Helvetica;
    font-size: 2vw;
    font-weight: 500;
    white-space: nowrap;
    padding-left: 1vw;
    /* letter-spacing: -1.3px; */
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    gap: 1.7vw;
}

.textNavbar {
    color: #000000;
    font-family: "Manrope", Helvetica;
    font-size: clamp(10px, 1.3vw, 19px);
    font-weight: 500;
    white-space: nowrap;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.textNavbar:hover {
    transform: scale(1.05);
}

.notLoggedIn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 12.5%;
    padding-left: 2.5%;
}

.btn {
    display: flex;
    align-items: center;
    height: clamp(1rem, 5vw, 3rem);
    width: 100%;
}

.containerBtn {
    display: flex;
    align-items: center;
    width: 50%;
}

.signUpBtn {
  white-space: nowrap;
  padding-left: 1.3vw;
  padding-right: 1.3vw;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.signUpBtn:hover {
    transform: translateY(-2px);
}

.textNickname {
    color: #555555;
    font-family: "Manrope", Helvetica;
    font-size: 0.9vw;
    font-weight: 500;
    white-space: nowrap;
    line-height: 1.4vw;
}

.myPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: clamp(1rem, 5vw, 3rem);
    width: 40%;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    margin-left: 0.5vw;
    cursor: pointer;
}

.myPage:hover {
    transform: scale(1.05);
}

.myPageIcon {
    color: #00732f;
    font-size: 2.3vw;
    padding: 5px;
}

.myPageText {
    font-size: 0.8vw;
    color: #00732f;
    font-weight: 500;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 10rem;
    z-index: 1000;
}

.menuItem {
    padding: 10px 15px;
    font-size: 1rem;
    color: #000000;
    cursor: pointer;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.menuItem:hover {
    background-color: #f5f5f5;
}

@media (max-width: 930px) {
    .textNavbar {
        color: rgba(0, 0, 0, 0);
        pointer-events: none;
    }
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        height: 60px;
        margin-top: 10px;
    }
    .logoContainer {
        width: 37%;
        padding-right: 2%;
        padding-left: 3.5%;
        margin-left: 15px;
    }
    .logo {
        height: 5vw;
        width: 5vw;
    }
    .logoHandubi {
        font-size: 3.5vw;
        font-weight: bold;
        padding-left: 2vw;
        letter-spacing: -1px;
    }
    .navbar {
        width: 30%;
    }
    .notLoggedIn {
        width: 25%;
        padding-right: 2.5%;
    }

    .textNickname {
        font-size: 2.2vw;
        line-height: 1.2;
        padding-right: 2px;
    }

    .myPageIcon {
        font-size: 3.2vw;
    }
    
    .myPageText {
        font-size: 1.3vw;
        align-items: center;
        justify-content: center;
    }

    .containerBtn {
        width: 70%; 
    }
    
    .signUpBtn {
      padding-left: 2vw;
      padding-right: 2vw;
    }
}

@media (max-width: 480px) {
    .container {
        height: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .logoContainer {
        padding-right: 0%;
        padding-left: 7.5%;
    }
    .navbar {
        width: 23%;
    }
    .notLoggedIn {
        width: 30%;
        padding-right: 2%;
    }
}