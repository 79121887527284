.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: clamp(10px, 2vw, 20px);
  padding: clamp(10px, 2vw, 20px);
  flex-wrap: wrap; /* 요소들이 줄 바꿈되도록 설정 */
}

.frame {
  align-items: center;
  background-color: #88ffc3;
  border-radius: 43px;
  display: flex;
  gap: clamp(5px, 1vw, 10px);
  height: clamp(25px, 3vw, 35px);
  justify-content: center;
  padding: clamp(10px, 1vw, 10px) clamp(10px, 2vw, 20px);
  color: black;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.frame:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  transform: translateY(-2px); /* Slightly raise the frame */
}

@media (max-width: 1150px) {
  .centerContainer {
    flex-wrap: wrap;
    gap: 3vw;
    justify-content: space-around;
    padding: 5vw;
  }
  .frame {
    flex: 1 1 45vw; /* 두 줄로 배치되도록 너비 조절 */
    max-width: 20vw;
    min-width: 13vw;
    min-height: 4vh;
    padding: clamp(10px, 10px, 10px) clamp(2vw, 3vw, 4vw);
  }
}

@media (max-width: 540px) {
  .centerContainer {
    flex-wrap: wrap;
    gap: 3vw;
    justify-content: space-around;
    padding: 4vw;
  }
  .frame {
    flex: 1 1 45vw; /* 두 줄로 배치되도록 너비 조절 */
    max-width: 20vw;
    min-width: 13vw;
    min-height: 4vh;
    gap: 1px;
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .centerContainer {
    flex-wrap: wrap;
    gap: 1vw;
  }
  .frame {
    margin: 2px;
    padding: clamp(10px, 10px, 10px) clamp(2vw, 3vw, 4vw);
  }
}