.footer {
    background-color: #00732f;
    color: #fff;
    padding: 40px 20px 40px 20px;
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .footer-section {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 30px;
    flex: 1;
    min-width: 20vw;
  }
  
  .footer-logo {
    padding-left: 20px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    white-space: normal;
    word-wrap: break-word;
  }

  .footer-title {
    padding-left: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    white-space: normal;
    word-wrap: break-word;
  }
  
  .social-icons i {
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .footer-section h4 {
    margin-bottom: 10px;
    font-size: 23px;
    color: #fff;
    padding-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    padding-left: 3px;
    margin-bottom: 8px;
    color: #fff;
    transition: transform 0.3s ease;
  }

  .footer-section ul li:hover {
    text-decoration: underline;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .footer-section p {
    margin: 5px 0;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  
/* ===== 반응형 스타일 추가 ===== */

/* 태블릿 화면 (너비 768px 이하) */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .footer-section {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 10px;
    min-width: 100%;
  }

  .footer-section h4 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 1px;
  }

  .footer-section p {
    font-size: 10px;
  }

  .footer-logo {
    font-size: 20px;
    padding-left: 0px;
  }

  .footer-title {
    font-size: 14px;
    padding-left: 0px;
  }

  .footer-bottom {
    font-size: 12px;
    margin-top: 20px;
  }
}

/* 모바일 화면 (너비 480px 이하) */
@media (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer-section {
    text-align: center;
    padding-bottom: 10px;
  }

  .footer-section h4 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 1px;
  }

  .footer-section p {
    font-size: 9px;
  }

  .footer-logo {
    font-size: 18px;
    text-align: center;
    padding-left: 0px;
  }

  .footer-title {
    font-size: 13px;
    text-align: center;
    padding-left: 0px;
  }
 
  .footer-bottom {
    margin-top: 10px;
    font-size: 12px;
  }
}

@media (max-width: 479px) {
  .footer {
    background-color: #f8f9fa;
    color: #00732f;
  }

  .social-icons i {
    color: #00732f;
  }
  
  .footer-section h4 {
    color: #00732f;
  }
  
  .footer-section ul li {
    color: #00732f;
  }
}