* {
  box-sizing: border-box; /* 모든 요소에 대해 box-sizing을 border-box로 설정 */
}

.handubi .space {
  height: 1vw;
  position: relative;
  left: 0;
  top: 0;
}

.handubi {
  margin: 0; /* 기본 여백 제거 */
  padding: 0; /* 기본 여백 제거 */
  width: 100%;
  height: 100%; /* 화면 높이를 100%로 설정 */
  display: flex; /* flexbox를 사용하여 자식 요소가 늘어날 수 있게 설정 */
  flex-direction: column; /* 페이지 내용이 수직으로 쌓이도록 설정 */
  overflow-x: auto;
}

.handubi .div {
  display: flex;
  flex-direction: column;
  height: 100%; /* 컨테이너가 화면의 전체 높이를 차지하게 설정 */
  overflow: hidden; /* 내용이 넘치면 자동으로 스크롤바가 생기도록 설정 */
}

.handubi .parent-group {
  background-color: #fffefb;
  flex: 0 1 auto;
  flex-grow: 0; /* 공간을 자동으로 차지하지 않도록 설정 */
  flex-shrink: 0; /* 축소되지 않도록 설정 */
  
  gap: 15px;
  /* 아래 세 가지 요소가 있어야 가운데 정렬됨 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handubi .text-wrapper-7 {
  color: #000000;
  font-family: "Manrope", Helvetica;
  font-weight: 500;
  letter-spacing: -2px;
  position: relative;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 30px;
  font-size: clamp(2rem, 5vw, 3rem); /* Adjust font size based on viewport */
}

.handubi .img {
  height: 480px;
  left: 105px;
  object-fit: cover;
  position: absolute;
  top: 148px;
  width: 313px;
}

.handubi .span {
  color: #000000;
  letter-spacing: -0.23px;
}

.handubi .p {
  color: rgba(179, 185, 186, 1);
  font-family: "Inter Tight", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 918px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1262px;
  white-space: nowrap;
}

.handubi .fi {
  height: 18px;
  left: 121px;
  position: absolute;
  top: 638px;
  width: 18px;
}

@media (max-width: 768px) {
  .handubi .text-wrapper-7 {
    letter-spacing: -1px;
  }
  .handubi .parent-group {
    gap: 10px;
    /* 아래 세 가지 요소가 있어야 가운데 정렬됨 */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .handubi .text-wrapper-7 {
    letter-spacing: -1px;
  }
  .handubi .parent-group {
    gap: 3px;
  }
  .handubi .space {
    height: 0.3vw;
  }
}