.commentSection {
    margin: 70px 10px 0px 10px;
    padding-bottom: 20px;
}

.title {
    padding: 0px 0px 20px 50px;
    font-size: 1.5em;
    color: #00732f;
}

.comment {
    padding: 20px;
    margin-left: 30px;
    margin-right: 30px;   
    line-height: 1.3;
}

.deletedComment {
    color: gray;
    font-style: italic;
    padding-top: 10px;
}

.commentBox {
    display: flex; /* Flexbox 활성화 */
    align-items: center; /* 세로 가운데 정렬 */
    justify-content: space-between; /* 양 끝 정렬 */
    padding-right: 50px;
}

.commentBoxwithReply {
    border-bottom: 1px solid rgb(230, 230, 230);
}

.commentContainer {
    background-color: #f6f6f6;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 5px;
}

.author {
    font-weight: bold;
}

.content {
    padding-top: 10px;
    word-break: break-word;
}

.createdAt {
    padding-top: 8px;
    font-size: 0.8em;
    color: #afaeae;
}

.commentForm {
    margin-top: 20px;
    margin: 20px 30px 0px 30px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
}

.input {
    width: 100%;
    height: 60px;
    padding: 20px;
    font-size: 14px;
    border: none;
    outline: none;
    resize: none;
    border-radius: 5px;
    margin-bottom: 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 5px 20px;
}

.options {
    display: flex;
    gap: 10px;
}

.optionButton {
    background: none;
    border: none;
    color: gray;
    cursor: pointer;
    font-size: 14px;
}

.optionButton:hover {
    color: black;
}

.submitButton {
    background-color: #00732f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #00431c;
}

.replyButton {
    background-color: #ffffff;
    color: rgb(38, 38, 38);
    padding: 4px 8px;
    border-color: rgb(171, 169, 169);
    border-width: 0.4px;
    cursor: pointer;
    margin-top: 10px;
    outline: none; /* 클릭 시 외곽선 제거 */
    box-shadow: none;
    border-radius: 2px;
}

.replyButton:hover {
    color: #00431c; /* 호버 시 색상 변경 */
}

.replyBox {
    margin: 0 50px 10px 50px;
    gap: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding-right: 20px;

    display: flex; /* Flexbox 활성화 */
    align-items: center; /* 세로 가운데 정렬 */
    justify-content: space-between; /* 양 끝 정렬 */
}

.replyTextBox {
    margin: 0 50px 20px 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.replyInput {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.replySubmitButton {
    align-self: flex-end;
    background-color: #00732f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.replySubmitButton:hover {
    background-color: #00431c;
}


@media (max-width: 500px) {
    .title {
        padding: 0px 0px 20px 50px;
        margin-left: 10px;
        margin-bottom: 10px;
        font-size: 1.5em;
        color: #00732f;
    }
    .commentSection {
        margin: 40px 0px 0px 0px;
        padding-bottom: 10px;
    }
    .commentForm {
        margin-top: 6px;
        margin: 20px 0px 3px 0px;
        padding: 2px;
    }
    .title {
        padding: 0px 0px 5px 5px;
        font-size: 1.2em;
        color: #00732f;
    }
    .commentContainer {
        margin-left: 5px;
        margin-right: 5px;
    }
    .commentBox {
        padding-right: 15px;
    }
    .comment {
        padding: 15px 8px 15px 20px;
        margin-left: 5px;
        margin-right: 0px;   
    }
    .content {
        padding-top: 5px;
    }
    .commentForm {
        margin-top: 0px;
        margin: 20px 10px 0px 10px;
        padding: 3px;
    }
    .replyBox {
        margin: 0 10px 10px 10px;
        padding-right: 20px;
    }
    .replyTextBox {
        margin: 0 10px 20px 10px;
        gap: 5px;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        background-color: #f9f9f9;
    }
}