  .container {
    gap: 10%;
    padding: 0px 0px 10px 0px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    color: #8b8888;
  }
  
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0; /* Add padding for spacing if needed */
    gap: 15px;
  }
  
  .header h2 {
    font-size: 2vw;
    font-weight: bold;
  }
  
  .viewAll {
    background-color: #056608;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: end;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .viewAll:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow effect */
    transform: translateY(-2px); /* Slightly raise the frame */
  }
  
  .square {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px;
    width: 80%;
    height: auto;
    margin: auto;
    aspect-ratio: 16 / 4;
    justify-content: center;
  }
  
  .newsItem {
    background-color: #f8f8f8;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row; /* Horizontal alignment of image and text */
    align-items: center; /* Vertically center content */
    padding: 8px;
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .newsImage {
    width: 40%;
    height: auto;
    margin-right: 2%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
  
  .newsTitle {
    color: #000000;
    font-weight: bold;
    padding-bottom: 5px;
    font-size: 1em;
    line-height:1.3;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
  }
  
  .newsBody {
    color: #767676;
    line-height:1.4;
    font-size: 0.8em;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
  }
  
  .newsItem:hover .newsImage {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .newsItem:hover .newsTitle {
    color: green;
    text-decoration: underline;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .newsContent h3 {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    padding-bottom: 3%;
  }
  
  .newsContent p {
    font-size: 14px;
    color: #555;
  }
  
  .square2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 30px;
  }
  
  .gridSquare2 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .table {
    width: 100%; /* 테이블 전체 너비 */
    border-collapse: separate;
    table-layout: fixed; /* 고정된 테이블 레이아웃 */
    border-spacing: 0 5px;
  }
  
  .header2 {
    text-align: center;
    font-weight: bold;
    color: #00732f;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .row {
    display: table-row;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    height: 25px; /* 행 높이를 고정 */
  }
  
  .row td {
    vertical-align: middle; /* 수직 가운데 정렬 */
  }
  
  .subCategory {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* 텍스트가 넘칠 경우 생략 표시 */
    justify-items: left;
  }
  
  .badge {
    background-color: #88ffc3;
    padding: 0.4vw 0.8vw;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    white-space: normal; /* 줄바꿈 허용 */
    word-break: break-word; /* 긴 단어도 줄바꿈 */
    flex-wrap: wrap; /* 텍스트 줄바꿈 활성화 */
    max-width: 100%; /* 부모 요소 크기를 넘지 않도록 제한 */
    font-size: 14px; /* 기본 폰트 크기 */
  }
  
  .title {
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left; /* 왼쪽 정렬 */
    padding: 0.1vw;
    text-decoration: none;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .title:hover {
    color: green; /* Green text color on hover */
    text-decoration: underline; /* Underline on hover */
  }
  
  .noData {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #999;
  }

  .noPicContainer {
    padding: 0px 0px 20px 0px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    color: #ffffff;
  }

  .noPicSquare {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .noPicTable {
    width: 100%; /* 테이블 전체 너비 */
    border-collapse: separate;
    table-layout: fixed; /* 고정된 테이블 레이아웃 */
    border-spacing: 0 5px;
  }

  .noPicHeader2 {
    text-align: center;
    font-weight: bold;
    color: #00732f;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .header3 {
    text-align: center;
    font-weight: bold;
    color: #00732f;
    padding: 10px;
    padding-bottom: 10px;
  }
  
  .noPicRow {
    display: table-row;
    cursor: pointer;
    height: 25px; /* 행 높이를 고정 */
  }
  
  .noPicRow td {
    vertical-align: middle; /* 수직 가운데 정렬 */
  }

  .noPicSubCategory {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* 텍스트가 넘칠 경우 생략 표시 */
    justify-items: left;
  }
  
  .noPicBadge {
    background-color: #88ffc3;
    padding: 0.4vw 0.6vw;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    white-space: normal; /* 줄바꿈 허용 */
    word-break: break-word; /* 긴 단어도 줄바꿈 */
    flex-wrap: wrap; /* 텍스트 줄바꿈 활성화 */
    max-width: 100%; /* 부모 요소 크기를 넘지 않도록 제한 */
    font-size: 12px; /* 기본 폰트 크기 */
  }
  
  .noPicTitle {
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left; /* 왼쪽 정렬 */
    padding: 0.1vw;
    text-decoration: none;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .noPicTitle:hover {
    color: green; /* Green text color on hover */
    text-decoration: underline; /* Underline on hover */
  }
  
  .noPicNoData {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #999;
  }

@media (max-width:1000px) {
  .newsTitle {
    font-size: 0.5em;
  } 
  .newsBody {
    font-size: 0.4em;
  }
  .newsItem {
    height: 12vw;
    padding: 5px;
  }
  .newsImage {
    width: 45%;
  }
}

@media (max-width:600px) {

  .square {
    grid-template-columns: 1fr;
    width: 85vw;
  }
  .newsImage {
    height: 11vw;
    margin-left: 2px;
    border-radius: 5px;
  }
  .newsTitle {
    font-size: 10px;
    padding-bottom: 0px;
  }
  .newsBody {
    display: none;
  }
  .newsItem {
    height: 12vw;
    padding: 0px;
  }
  .noPicContainer {
    width: 100%;
  }
  .noPicHeader2{
    font-size: 0.8em;
  }
  .noPicNoData{
    font-size: 0.8em;
  }
  .noPicTable {
    width: 100%;
  }
  .noPicSquare {
    gap: 5px;
  }
  .noPicTitle {
    display: block;
    font-size: 0.8em;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
  .noPicBadge {
    display: none;
  }
  .noPicSubCategory {
    display: none;
  }
  .noPicRow {
    display: block;
    width: 100%;
    overflow: hidden;
    min-width: 160px;
  }
}